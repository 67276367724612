











































import { Note } from "@/models/entities/note.interface";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AppBarTab } from "@/models/utils/c-tab.interface";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import TabModule from "@/store/modules/tab.module";
const tabModule = getModule(TabModule, store);

const CNotes = () =>
  import(/* webpackChunkName: "CNotes" */ "./components/CNotes.vue");
const CArchivedMessage = () =>
  import(
    /* webpackChunkName: "CArchivedMessage" */ "./components/CArchivedMessage.vue"
  );

@Component({
  components: {
    "c-notes": CNotes,
    "c-archive-message": CArchivedMessage,
  },
})
export default class CNotesArea extends Vue {
  @Prop({ required: true, type: Array })
  private notes: Note[];

  private localNotes = [] as Note[];
  private newNote = null as string;
  private showAddNotes = false;
  private showArchiveMessage = false;

  get tab(): AppBarTab {
    return tabModule.current;
  }

  private created(): void {
    this.localNotes = this.notes;
  }

  @Watch("notes")
  private onNotesChange(): void {
    this.localNotes = this.notes;
  }

  private onAddNoteClick(): void {
    this.newNote = null as string;
    this.showAddNotes = true;
  }

  private onNotesInput(item: string): void {
    this.newNote = item;
  }

  private saveNotes(): void {
    this.showAddNotes = false;
    this.$emit("save-notes", this.newNote);
  }

  private cancelNotes(): void {
    this.showAddNotes = false;
    this.$emit("cancel-notes");
  }
}
